@import '../../sass/mixin';
@import '../../sass/gap';

@mixin white-gradient {
	background: linear-gradient(to right, var(--bg-slider-color) 0%, var(--bg-slider-color-2) 100%);
}

$animationSpeed: 20s;


@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-250px * 7))
	}
}



.slider {
	position: relative;
	height: 150px;
	width: 100%;
	margin: auto;
	overflow: hidden;
	background: var(--container-color);
	box-shadow: 0 10px 20px -5px rgba(var(--shadow-color), .125);

	&::before,
	&::after {
		position: absolute;
		content: "";
		height: 150px;
		width: 80px;
		z-index: 2;
		@include white-gradient;
	}

	&::after {
		right: 0;
		top: 0;
		@include translate(rotateZ(180deg));
	}

	&::before {
		left: 0;
		top: 0;
	}

	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
		margin-top: $gap-2;
	}

	.slide {
		height: 100px;
		width: 250px;
	}
}


@media screen and (min-width: 768px){
	.slider::before,
	.slider::after{
		width: 200px;
	}
}
@import '../../sass/mixin';
@import '../../sass/gap';


.contact {
    transform: translateY(-10px) !important;

    &__container {
        position: relative;
        height: max-content;
        padding: $gap-3;
        background: var(--container-color);
        overflow: hidden;

        &--item {
            width: 100%;
            height: 480px;
            transition: transform 1.6s ease-in-out;
        }

    }


    &__sub-contaire {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 30%;
        background: #fff;
        @include transition(transform 1.2s ease-in-out);

        &--img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            padding-top: 160px;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                right: 0;
                top: 0;
                background-image: url("https://res.cloudinary.com/fk-web-agency/image/upload/v1626285002/chrono-pieces-auto/Home-About-Contact/contact-1_wlvwmu.jpg");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                @include transition(transform 1.2s ease-in-out);
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.5);
            }
        }

        &--text {
            position: absolute;
            width: 100%;
            left: 0;
            top: $gap-2;
            padding: 0 $gap-3;
            color: #fff;
            text-align: center;
            z-index: 2;
            @include transition(transform 1.2s ease-in-out);

            a {
                color: #fff;
                cursor: pointer;
            }

            h2 {
                width: 100%;
                font-weight: var(--normal-font-size);
                font-size: var(--h2-font-size);
                text-align: center;
                margin-bottom: $gap-1 + .2rem;
            }

            p {
                font-size: var(--small-font-size);
                line-height: 1.5;
            }

            &.m--in {
                @include translate(translateX(-750px));
            }
        }

        &--btn {
            position: relative;
            width: 120px;
            height: 36px;
            margin: 0 auto;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-size: 15px;
            z-index: 2;
            overflow: hidden;
            cursor: pointer;

            &:after {
                content: "";
                z-index: 2;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid #fff;
                @include border-radius($gap-1);
            }

            span {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                @include d-flex(center, center);
                @include transition(transform 1.2s);

                &.m--in {
                    @include translate(translateY(-72px));
                }
            }
        }

    }

    &__form {
        &--radio {
            gap: $gap-3;
            margin-bottom: $gap-2;
            @include d-flex(flex-start, center);
        }

        &--select {
            margin-bottom: $gap-2;
        }
    }

    &__verify {
        margin-top: $gap-4;
        gap: $gap-3;
        @include d-flex(center, center);
        @include flex-column;
    }
}


/* FORM  */
input,
button,
textarea,
select {
    border: none;
    outline: none;
    background: none;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;

}

input,
textarea,
select {
    display: block;
    width: 100%;
    margin-top: $gap-1;
    padding-bottom: $gap-1;
    font-size: var(--normal-font-size);
    border-bottom: 1px solid rgba(var(--shadow-color), 0.4);
    text-align: center;
    color: var(--text-color);
}

textarea {
    resize: vertical;
}



/* SWITCH CONTENT HERE ! */
.contact__container.switch {

    .contact-form {
        transition-timing-function: ease-in-out;
        transition-duration: 1.2s;
        @include translate(translate3d(-1500px, 280px, 0));
    }

    .contact {

        &__sub-contaire {
            &--img:before {
                background-image: url("https://res.cloudinary.com/fk-web-agency/image/upload/v1626285002/chrono-pieces-auto/Home-About-Contact/contact-2_wlvker.jpg");
                background-position: center center;
            }

            &--text.m--up {
                @include translate(translateX(800px));
            }

            &--btn span.m--in,
            &--text.m--in {
                @include translate(translateY(0));
            }

            &--btn span.m--up {
                @include translate(translateY(72px));
            }
        }
    }



    .maps {
        @include translate(translate3d(0, -190px, 0));
        color: var(--text-color);

        h2 {
            margin-bottom: $gap-2;
        }

        a{
            color: var(--first-color-alt);
            cursor: pointer;
        }

    }
}

.maps {
    padding-bottom: 2.5rem;
    @include translate(translate3d(1500px, -190px, 0));
}

.contact-form {
    /*  padding: 18rem 0 0; */
    @include translate(translate3d(0px, 280px, 0));
    transition-timing-function: ease-out;
}




/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
    .contact__sub-contaire {

        &--img {
            padding: 190px;
        }

        &--text {
            top: $gap-4;
        }
        .m--in {
            @include translate(translateX(-1000px));
        }
    }

    .contact__verify {
        flex-direction: row;
    }

    .contact__container.switch {
        .contact-form {
            @include translate(translate3d(-1500px, 280px, 0));
        }

        .contact {

            &__sub-contaire {
                &--img:before {
                    background-image: url("https://res.cloudinary.com/fk-web-agency/image/upload/v1626285002/chrono-pieces-auto/Home-About-Contact/contact-2_wlvker.jpg");
                    background-position: center 0px;
                }

                &--text.m--up {
                    @include translate(translateX(1000px));
                }
            }
        }

    }




    .maps {
        @include translate(translate3d(1000px, -190px, 0));
    }
}
/*
── ──────────────────────────────────────────────── I ──────────
──  :::::: F L E X B O X : :  :   :    :     :        :          :
── ──────────────────────────────────────────────────────────
*/
@mixin d-flex ($justify:'flex-start', $align:'flex-start') {
    display: flex;
    display: -ms-flexbox;
    align-items: $align;
    -webkit-box-justify: $justify;
    -ms-flex-justify: $justify;
    justify-content: $justify;
}

@mixin flex-column {
    flex-direction: column;
}

/*
── ──────────────────────────────────────────────── II ──────────
──  :::::: G R I D : :  :   :    :     :        :          :
── ──────────────────────────────────────────────────────────
*/
@mixin d-grid ($justify:'start', $align:'start') {
    display: grid;
    display: -ms-grid;
    justify-content: $justify;
    align-items: $align;
}

@mixin grid-fit ($min, $max) {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(auto-fit, minmax($min, $max));
}


/*
── ──────────────────────────────────────────────── III ──────────
──  :::::: D E S I G N : :  :   :    :     :        :        :
── ──────────────────────────────────────────────────────────
*/
@mixin reset {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@mixin border($val) {
    border: $val;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin box-shadow($val) {
    box-shadow: $val;
}


@mixin filter {
    -webkit-filter: $val;
            filter: $val;
}
@mixin object-fit($val) {
    -o-object-fit: $val;
       object-fit: $val;
}

@mixin backdrop ($val){
    -webkit-backdrop-filter: $val;
            backdrop-filter: $val;
}
/*
── ──────────────────────────────────────────────── III ──────────
──  :::::: A N I M A T I O N : :  :   :    :     :        :
── ──────────────────────────────────────────────────────────
*/

@mixin translate ($val) {
    transform: ($val);
}

@mixin animation {
    -webkit-animation: $val;
    animation: $val;
}

@mixin transition ($val) {
    transition: $val;
}
@import '../../sass/gap';

.footer {
    &__container {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    &__logo {
        color: var(--title-color);
    }

    &__title {
        margin-bottom: var(--mb-2);
        a{
            color: var(--title-color);
        }
    }

    &__logo,
    &__title {
        font-size: var(--h3-font-size);
    }

    &__link {
        margin-bottom: .75rem;
        color: var(--text-color);
        cursor: pointer;

        &:hover {
            color: var(--first-color);
        }
    }

    &__social {
        font-size: 1.5rem;
        color: var(--title-color);
        margin-right: var(--mb-3);
    }

    &__copy {
        text-align: center;
        font-size: var(--small-font-size);
        
        margin-top: $gap-4;
        &:hover{
            color: var(--text-color-light);
        }
        span{
            color: var(--title-color);
        }
    }

}
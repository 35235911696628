@import '../../sass/gap';
@import '../../sass/mixin';

/*========== BANNER ==========*/
.banner {
    text-transform: capitalize;
    padding: $gap-1 $gap-3;
    gap: $gap-2;
    background: #ffcd01;
    @include d-flex(center, flex-start);
    @include flex-column;

&__logo{
    margin: 2rem auto 0;
  img{
      transform: scale(1.4);
  }
}


    &__buttons {
        width: 100%;
        gap: $gap-2;
        @include d-flex(center, center);
        .button:nth-child(1) {
            background: #C42130;
        }
        .button:nth-child(2) {
            background: var(--button-phone-color);
        }
    }

    &__timetable {
        margin: auto;
        padding: $gap-2 0;
        font-size: var(--normal-font-size);
        color: #F3F1F1;
        font-weight: var(--font-semi-bold);

        &--opening {
            text-align: center;
            text-transform: uppercase;
            font-size: var(--h2-font-size);
            font-weight: var(--font-semi-bold);
            color: #C42130;
        }

        .green {
            color: green;
        }
    }

}

/*===== HIDE BANNER =====*/
.banner-hidden {
    display: none;
}

/*========== NAV ==========*/
.nav {
    height: var(--header-height);
    margin: auto;
    @include d-flex(space-between, center);

    &__menu {
        @media screen and (max-width: 768px) {
            position: fixed;
            top: -100%;
            left: 0;
            right: 0;
            width: 90%;
            margin: 0 auto;
            padding: $gap-5 0 0;
            text-align: center;
            background-color: var(--body-color);
            box-shadow: 0 0 4px rgba(0, 0, 0, .1);
            border-radius: $gap-4;
            z-index: var(--z-fixed);
            @include transition(.6s);
        }
    }

    &__item {
        margin-bottom: $gap-3;
    }

    &__link,
    &__logo,
    &__toggle {
        color: var(--title-color);
        font-weight: var(--font-semi-bold);
        font-size: var(--normal-font-size);
    }

    &__logo {
        &:hover {
            color: var(--text-color-light);
        }
    }

    &__link {
        transition: .3s;
        @include transition(.3s);

        &:hover {
            color: var(--text-color-light);
        }
    }

    &__toggle {
        font-size: $gap-3;
        cursor: pointer;
    }

    .show-menu {
        top: $gap-8;
        @include transition(.6s)
    }

    a {
        cursor: pointer;
    }
}

.nav-2 .show-menu-top {
    top: 27.5rem;
}


.scrolltop {
    position: fixed;
    right: $gap-2;
    bottom: $gap-3;
    padding: .3rem;
    background: orange;
    opacity: .7;
    border-radius: $gap-1;
    z-index: var(--z-tooltip);
    visibility: visible;
    @include d-flex(center, center);
    @include transition(.4s);

    &:hover {
        background-color: var(--first-color);
    }

    &__icon {
        font-size: $gap-3;
        color: var(--body-color);
    }
}


/*===== ACTIVE NAVLINK =====*/
.active-link {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -.75rem;
        left: 45%;
        width: 5px;
        height: 5px;
        background-color: var(--title-color);
        border-radius: 50%;
    }
}

/* MEDIA QUERIES */

@media screen and (min-width: 768px) {
    .banner {
        flex-direction: row;
        padding: $gap-3 $gap-1 $gap-2;
        @include d-flex(space-between, center);

        &__buttons {
            width: auto;
            a{
                cursor: pointer;
            }
        }


        &__timetable {
            margin: 0;
        }

        &__logo {
            margin: auto;
            img{
                    transform: scale(1.2);
            }
        }
    }

    .nav {
        height: calc(var(--header-height) + 1.5rem);

        &__list {
            @include d-flex(flex-start, center)
        }

        &__item {
            margin: 0 0 0 $gap-5;
        }

        &__toggle {
            display: none;
        }
    }

    .change-theme {
        position: initial;
        margin-left: $gap-4;
    }
}
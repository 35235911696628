@import './sass/gap';
@import './sass/mixin';


@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:wght@200;400;500;600;700;800&display=swap');
/* Reset */
*,*:before, *:after {
  @include reset;
}

/* Global Settings */
:root {
  --header-height: 3rem;

  /* Colors */
  --first-color: #fbd015;
  --first-color-alt: #D1C7C8;
  --first-color-send: #DE3B49;
  --title-color: orange;
  --text-color: #5B5757;
  --text-color-light: #8F8A8A;
  --button-phone-color: black;
  --button-close-color: rgba(17, 17, 17, 0.316);
  --shadow-color: 65, 11, 16;
  --bg-slider-color: rgba(255,255,255,1);
  --bg-slider-color-2: rgba(255,255,255,0);
  --body-color: #FEFBFB;
  --container-color: #FFF;
  /* Fonts */
  --biggest-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;

  --font-semi-bold: 600;
  --font-bold: 700;

  /* z index */
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Media queries */
@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

/* Body */
body {
  margin: var(--header-height) 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
}

/* Variables Dark theme */
body.dark-theme {
  --first-color-send: #161212;
  --title-color: #F3F1F1;
  --text-color: #D1C7C8;
  --button-close-color: rgba(242, 227, 227, 0.5);
  --shadow-color: 243, 241, 241;
  --bg-slider-color: rgb(51, 49, 49);
  --bg-slider-color-2: rgba(255,255,255,0);
  --body-color: #251D1E;
  --container-color: #302728;
}

/* Button Dark/Light */
.change-theme {
  position: absolute;
  right: $gap-3;
  top: 2.2rem;
  color: var(--title-color);
  font-size: $gap-4;
  cursor: pointer;
  @include d-flex;
}


html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
ul,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}


/*========== CLASS CSS ==========*/
.section {
  padding: $gap-4 0 $gap-4;
  overflow-x: hidden;

  &__title,
  &__title-center {
    font-size: var(--h2-font-size);
    color: var(--title-color);
    text-align: center;
    margin-bottom: $gap-3;
    text-transform: capitalize;
  }
}

/*========== LAYOUT ==========*/
.l-main {
  overflow: hidden;
}

.bd-container {
  max-width: 968px;
  width: calc(100% - 3rem);
  margin-left: $gap-3;
  margin-right: $gap-3;
}

.bd-grid {
  display: grid;
  gap: $gap-3;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.button {
  display: inline-block;
  padding: .7rem $gap-2;
  background-color: var(--first-color-send);
  color: #fff;
  font-weight: var(--font-semi-bold);
  @include border-radius($gap-1);
}



/* CUSTOM GOOGLE WIDGET */
.gyMiiK,
.iUZRQQ,
.clqcqP {
  background: var(--container-color) !important;

}

.kJdqZS {
  background: var(--container-color) !important;
}

.clqcqP *,
.iUZRQQ *,
.gyMiiK *,
.kJdqZS {
  color: var(--text-color) !important;
}

.gyMiiK a {
  display: none !important;
}

.cMbGNq,
.ekryUH {
  background: var(--body-color) !important;
}

.kVCYUW {
  background-color: var(--button-close-color) !important;
}

.gkjipq{
  z-index: 100!important;
  right: 40px!important;
}
/* MEDIA QUERIES */
@media screen and (min-width: 968px) {
  .bd-container {
/*     margin-left: auto;
    margin-right: auto; */
    margin-inline: auto;
  }
}
@import '../../sass/mixin';
@import '../../sass/gap';


.product {
    &__container {
        gap: $gap-4;
        margin-top: $gap-4;
        @include grid-fit(220px, 1fr);
    }

    &__card {
        position: relative;
        background-color: var(--container-color);
        padding: $gap-2 $gap-2 $gap-4;
        text-align: center;
        overflow: hidden;
        grid-template-rows: 180px repeat(2,auto );
        @include d-grid;
        @include border-radius($gap-2);
        @include box-shadow(0 2px 6px rgba(var(--shadow-color), .15));
        @include transition(all .5s ease-in);

        &--img {
            width: 180px;
            margin: auto;
            @include transition(all .7s ease-in);
        }

        &--title {
            font-size: var(--h3-font-size);
            margin-bottom: $gap-1;
        }

        &--description {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $gap-1 $gap-2;
            font-size: var(--normal-font-size);
            color: var(--text-color);
            background: var(--body-color);
            gap: $gap-2;
            @include d-grid;
            @include transition(.5s);
            @include translate(translateY(100%));
            @include transition(all .7s ease-in);
            cursor:default;
            &-text{
                    color: var(--first-color-alt);
            }
            &--price {
                color: var(--text-color-light);
            }

            span {
                font-weight: var(--font-semi-bold);
                color: var(--first-color-alt);
            }

        }

        /* HOVER CARD */
        &:hover {
            @include box-shadow(0 3px 12px rgba(var(--shadow-color), .15));

            .product__card--description {
                transform: translateY(0);
            }

            .product__card--img {
                transform: scale(1.2);
            }
        }
    }


}
@import '../../sass/mixin';
@import '../../sass/gap';

.about {
    &__container {
        gap: $gap-3;
        @include grid-fit(45vw, 1fr);
    }

    &__img {
        img {
            opacity: .7;
        }
    }

    &__text {
        color: var(--text-color);
        #link{
            text-transform: none;
            color: orange;
            cursor: pointer;
        }
    }
}



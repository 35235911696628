@import '../../sass/mixin';

.home {
    position: relative;
    margin-top: 14rem;
    overflow: hidden;
    font-family: 'Lobster', cursive;
    &__banner {
        width: 100vw;
        height: 450px;

        img {
            object-fit: cover;
            height: 100%;
        }
    }

    &__title {
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
        font-size: var(--biggest-font-size);
        text-transform: capitalize;
        color: var(--container-color);
        text-align: center;
    }
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
    .home {
        margin: auto;
        &__banner {
            height: 650px;
        }
    }
}
@import '../../sass/mixin';
@import '../../sass/gap';

.promo{
&__heading{
    font-size: var(--h2-font-size);
    text-transform: capitalize;
}

    .banner__buttons{
        justify-content: flex-start;
        margin-block: $gap-3;
    }
}
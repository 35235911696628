$gap-1: .5rem;
$gap-2: 1rem;
$gap-3: 1.5rem;
$gap-4: 2rem;
$gap-5: 2.5rem;
$gap-6: 3rem;
$gap-7: 3.5rem;
$gap-8: 4rem;
$gap-9: 5.5rem;
$gap-10: 6rem;
$gap-11: 6.5rem;
$gap-12: 7rem;
$gap-13: 7.5rem;
$gap-14: 8rem;
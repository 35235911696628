@import '../../sass/mixin';
@import '../../sass/gap';

#notfound {
    position: relative;
    height: 100vh;
    overflow: hidden;

    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 520px;
        width: 100%;
        text-align: center;
        line-height: 1.4;
        gap: $gap-2;
        @include translate(translate(-50%, -50%));
        @include d-grid;

        &-404 {
            height: 190px;

            h1 {
                font-family: 'Montserrat', sans-serif;
                font-size: 146px;
                font-weight: 700;
                margin: 0px;
                color: #232323;

                span {
                    display: inline-block;
                    width: 120px;
                    height: 120px;
                    background-image: url('https://res.cloudinary.com/fk-web-agency/image/upload/v1626286178/chrono-pieces-auto/Home-About-Contact/emoji_j3do8g.png');
                    background-size: cover;
                    z-index: -1;
                    @include translate(scale(1.4));
                }
            }

        }

        .button {
            background: orange;
        }

    }
}


@media only screen and (max-width: 767px) {
    #notfound{
        height: 90vh;
    }
    .notfound {
        padding: $gap-1;

        &-404 {
            height: 115px;

            h1 {
                font-size: 86px;

                span {
                    width: 86px;
                    height: 86px;
                }
            }
        }
    }

}